import axios from '@/libs/axios';

const baseUrl = '/admin/kiosk-global-css';

export default {
  getCss() {
    return axios.get(`${baseUrl}`);
  },
  updateCss(css) {
    return axios.put(`${baseUrl}`, css)
  },
}
