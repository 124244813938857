<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="kiosk-global-css-page">
    <b-card class="" title="Kiosk Global CSS">
      <b-card-text>View global CSS for the Kiosk application</b-card-text>
    </b-card>

    <b-card no-body class="mb-0 p-2">
      <section>

        <b-input-group>
          <b-form-textarea v-model="cssRules" rows="15"></b-form-textarea>
        </b-input-group>
        <b-button variant="primary" @click="updateCSS" class="mt-1">Update</b-button>

      </section>

    </b-card>

  </div>
</template>
<!--eslint-enable-->

<script>
import KioskGlobalCssService from '@/services/KioskGlobalCssService';
import helperService from '@/services/HelperService';

export default {
  name: 'KioskSite',
  props: {},
  data() {
    return {
      cssRules: '',
    };
  },
  mounted() {
    this.getCSS();
  },
  methods: {
    async updateCSS() {
      await KioskGlobalCssService.updateCss({css_rules: this.cssRules});
      helperService.showNotfySuccess(this.$toast, `Updated global css successfully`)
    },
    async getCSS() {
      try {
        const res = await KioskGlobalCssService.getCss()
        this.cssRules = res.data.css_rules;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get global css, please refresh and try again')
      }
    },
  },
};
</script>
